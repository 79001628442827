import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // If using React Router
import "./Payment.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Payment() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  // Extracting payment details
  const paymentData = {
    resErrorNo: queryParams.get("ResErrorNo"),
    resErrorText: queryParams.get("ResErrorText"),
    paymentID: queryParams.get("paymentid"),
    ref: queryParams.get("ref"),
    postDate: queryParams.get("postdate"),
    resultCode: queryParams.get("result"),
    amount: queryParams.get("amt"),
    tranID: queryParams.get("tranid"),
    auth: queryParams.get("auth"),
    trackID: queryParams.get("trackid"),
    udf1: queryParams.get("udf1"),
    udf2: queryParams.get("udf2"),
    udf3: queryParams.get("udf3"),
  };

  useEffect(() => {
    // Store payment data in session storage
    sessionStorage.setItem("paymentData", JSON.stringify(paymentData));

    // Redirect if trackID contains "GroupClass"
    if (paymentData.trackID && paymentData.trackID.includes("GroupClass")) {
      navigate("/courses");
    }
  }, [paymentData.trackID, navigate]);

  return (
    <div className="transaction-details-table">
      <div className="container">
        <h2>YASA-KNET Transaction Details</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }} aria-label="simple table">
            <TableBody>
              {Object.entries(paymentData).map(([key, value]) => (
                value && (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">{key}</TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Payment;
