import * as actionTypes from "../actions/actionTypes";

const initialStateGetCourse = {};
const initialStateGetSchedule = [];
const initialStateGetPayLink = {};


export const coursesReducer = (state = initialStateGetCourse, action) => {
  switch (action.type) {
    case actionTypes.GET_COURSES:
      return {
        courses: action.payload.courses,
      };

    default:
      return state;
  }
};

export const GetGroupClassScheduleReducer = (state = initialStateGetSchedule, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUP_CLASS:
      return {
        groupClass: action.payload.groupClass,
      };

    default:
      return state;
  }
};

export const getPaymentLinkReducer = (state = initialStateGetSchedule, action) => {
  switch (action.type) {
    case actionTypes.ENROLL_NOW:
      return {
        paymentUrl: action.payload.paymentUrl,
      };

    default:
      return state;
  }
};




