import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const getCoursessSuccess = (data) => {
  const noClasses = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_COURSES,
    payload: {
      courses: noClasses ? [] : data.Data,
    },
  };
};

export const GetGroupClassScheduleSuccess = (data) => {
  return {
    type: actionTypes.GET_GROUP_CLASS,
    payload: {
      groupClass: data.Data,
    },
  };
};


export const getPaymentLinkSuccess = (data) => {
  const noClasses = isObjectEmpty(data.Data)
  return {
    type: actionTypes.ENROLL_NOW,
    payload: {
      paymentUrl: noClasses ? [] : data.Data,
    },
  };
};

export const getCourses = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetGroupCourses`)
    .then((response) => {
      if (response) {
        dispatch(getCoursessSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};

export const GetGroupClassSchedule = (data) => async (dispatch) => {
  return await apiCall
  .axiosPost(`${API_BASE_URL}/GetGroupClassSchedule`, data)
    .then((response) => {
      if (response) {
        dispatch(GetGroupClassScheduleSuccess(response.data))
        return response.data;
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
  };
  
  export const getPaymentLink = (data) => async (dispatch) => {
    return await apiCall
      .axiosPost(`${API_BASE_URL}/GetGroupCoursesPaymentURL`,data)
      .then((response) => {
        if (response) {          
          dispatch(getPaymentLinkSuccess(response.data));
          return response.data;
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };