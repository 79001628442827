import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import i18n from "./i18n";

import { BrowserRouter } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import configureStore from "./store";
import { Provider } from "react-redux";
import App from "./App.js";

const store = configureStore();
TimeAgo.addDefaultLocale(en);

export default function Main() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const setLanguage = () => {
    if (localStorage.getItem("I18N_LANGUAGE") === "en") {
      localStorage.setItem("I18N_LANGUAGE", "en");
      i18n.changeLanguage("en");
      document.body.dir = i18n.dir();
    }
  };
  
  function loadScript() {
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_AGORA_URL
      if (document.querySelector(`script[src="${url}"]`)) {
        resolve(); // Script already loaded
        return;
      }
  
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
      document.head.appendChild(script);
    });
  }

  useEffect(() => {
    setLanguage();

    // Load the script in the background
    loadScript().catch((error) => {
      console.error("Failed to load Agora script:", error);
     });
  }, []);


  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root-main"));
root.render(<Main />);
